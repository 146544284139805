import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import messages from '@utils/messages'

const useStyles = makeStyles((theme: Theme) => ({
  validationError: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
  },
  validationErrorsList: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
  truncationButton: {
    paddingLeft: theme.spacing(10),
  },
}))

interface ValidationErrorsProps {
  errors?: string[]
  truncate?: number
}

const ValidationErrors: React.FC<ValidationErrorsProps> = ({
  errors,
  truncate = 5,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(false)
  }, [errors])

  if (!errors || errors.length === 0) {
    return null
  }

  const errorMessage = (
    <>
      <FormattedMessage {...messages.MULTI_PRODUCT_INVALID_INPUT} />
      {errors.length <= truncate || open ? (
        <ul className={classes.validationErrorsList}>
          {errors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
      ) : (
        <>
          <ul className={classes.validationErrorsList}>
            {errors.slice(0, truncate).map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
          <Link
            className={classes.truncationButton}
            component="button"
            onClick={() => setOpen(true)}
          >
            <FormattedMessage
              {...messages.INVALID_INPUT_VIEW_MORE}
              values={{
                remaining: errors.length - truncate,
              }}
            />
          </Link>
        </>
      )}
    </>
  )

  return <div className={classes.validationError}>{errorMessage}</div>
}

export default ValidationErrors
