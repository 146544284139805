import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { homeRoute, orderCenterRoutes } from '@src/routes'
import { Linq } from '@src/components/Linq'
import DefaultLayout from '@src/components/DefaultLayout'
import { sendErrorPageEvent } from '@src/utils/analytics'
import Head from 'next/head'
import { useCurrentUser } from '@src/utils/useCurrentUser'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(92),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1,
  },
  // Line-height fix for Firefox and Edge
  '@supports (-ms-ime-align:auto)': {
    title: {
      lineHeight: 0.3,
      margin: theme.spacing(50, 0, 0),
    },
  },
  container: {
    margin: theme.spacing(5, 'auto', 20),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(15),
      display: 'grid',
      justifyContent: 'space-around',
      gridTemplateColumns: '1fr 1fr',
      textAlign: 'unset',
    },
  },
  message: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(26),
    margin: theme.spacing(5, 0, 12),
  },
  button: {
    marginTop: theme.spacing(12),
  },
  quickLinksContainer: {
    fontSize: '18px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  quickLinks: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
    },
  },
  quickLink: {
    cursor: 'pointer',
    fontSize: '16px',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(2),
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      paddingRight: theme.spacing(5),
    },
    '&:not(:first-child)': {
      paddingLeft: theme.spacing(5),
    },
  },
  image: {
    margin: theme.spacing(10, 'auto', 0),
    padding: theme.spacing(0, 5),
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
}))

const Error404: React.FC<{ statusCode: number }> = ({ statusCode }) => {
  const classes = useStyles()
  const intl = useIntl()

  // TODO: Remove isUsUser once invoice list is available to all countries
  const {
    currentUser,
    userIsLoggedIn,
    isQuoteEnabled,
    isQuoteRequester,
    isQuoteConverter,
  } = useCurrentUser()
  const isUsUser: boolean =
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser.metadata?.country === 'US'

  const hasQuoteAccess =
    isQuoteEnabled && (isQuoteRequester || isQuoteConverter)

  useEffect(() => {
    sendErrorPageEvent(
      statusCode,
      "Oops We can't find the page you were looking for."
    )
  }, [])

  const isGraphQLBroken = currentUser === null

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: 'ERROR_404_PAGE_TITLE',
            defaultMessage: 'Not Found | Sigma Aldrich',
          })}
        </title>
      </Head>
      <DefaultLayout className={classes.root}>
        <Container maxWidth="lg" className={classes.container}>
          {isGraphQLBroken ? (
            <>
              <div>
                <h1 className={classes.title}>
                  <FormattedMessage
                    id="ERROR_GENERIC_TITLE"
                    defaultMessage="Oops!"
                  />
                </h1>

                <div className={classes.message}>
                  <FormattedMessage
                    id="ERROR_DATA_SOURCE_MESSAGE"
                    defaultMessage="Data source not found."
                  />
                </div>
              </div>
              <img
                src="/static/503-error.png"
                alt="503 Server Error"
                className={classes.image}
              />
            </>
          ) : (
            <>
              <div>
                <h1 className={classes.title}>
                  <FormattedMessage
                    id="ERROR_GENERIC_TITLE"
                    defaultMessage="Oops!"
                  />
                </h1>

                <div className={classes.message}>
                  <FormattedMessage
                    id="ERROR_404_MESSAGE"
                    defaultMessage="We can't find the page you were looking for."
                  />
                </div>
                <div className={classes.quickLinksContainer}>
                  <FormattedMessage
                    id="ERROR_404_QUICK_LINK_MESSAGE"
                    defaultMessage="Here are some quick links you might like to visit:"
                  />
                  <div className={classes.quickLinks}>
                    {userIsLoggedIn ? (
                      <>
                        <Linq
                          href={orderCenterRoutes.orders()}
                          className={classes.quickLink}
                        >
                          <FormattedMessage
                            id="ORDERS"
                            defaultMessage="Orders"
                          />
                        </Linq>
                        {hasQuoteAccess && (
                          <Linq
                            href={orderCenterRoutes.quotes()}
                            className={classes.quickLink}
                          >
                            <FormattedMessage
                              id="QUOTES"
                              defaultMessage="Quotes"
                            />
                          </Linq>
                        )}
                        {isUsUser && (
                          <Linq
                            href={orderCenterRoutes.invoiceList()}
                            className={classes.quickLink}
                          >
                            <FormattedMessage
                              id="INVOICES"
                              defaultMessage="Invoices"
                            />
                          </Linq>
                        )}

                        {currentUser?.metadata?.helpLinkURL && (
                          <Linq
                            href={currentUser.metadata.helpLinkURL}
                            className={classes.quickLink}
                          >
                            <FormattedMessage id="HELP" defaultMessage="Help" />
                          </Linq>
                        )}
                      </>
                    ) : (
                      <>
                        <div className={classes.quickLink}>
                          <Linq href={homeRoute.index()}>
                            <FormattedMessage id="HOME" defaultMessage="Home" />
                          </Linq>
                        </div>
                        <div className={classes.quickLink}>
                          <Linq href={orderCenterRoutes.orders()}>
                            <FormattedMessage
                              id="ORDERS"
                              defaultMessage="Orders"
                            />
                          </Linq>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <img
                src="/static/404-not-found.png"
                alt="404 Page not found"
                className={classes.image}
              />
            </>
          )}
        </Container>
      </DefaultLayout>
    </>
  )
}

export default Error404
