import React, { useEffect } from 'react'
import Head from 'next/head'
import { homeRoute } from '@src/routes'
import { sendErrorPageEvent } from '@src/utils/analytics'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Linq } from '@src/components/Linq'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  smallPadding: {
    margin: '20px 30px',
  },
  purpleLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const Simple404: React.FC<{ statusCode: number }> = ({ statusCode }) => {
  const intl = useIntl()
  const classes = useStyles()

  useEffect(() => {
    sendErrorPageEvent(statusCode, 'Not Found | Sigma Aldrich')
  }, [])

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: 'ERROR_404_PAGE_TITLE',
            defaultMessage: 'Not Found | Sigma Aldrich',
          })}
        </title>
      </Head>
      <div className={classes.smallPadding}>
        <p>
          Not found, or not allowed.{' '}
          <Linq href={homeRoute.index()} className={classes.purpleLink}>
            Go Home &rarr;
          </Linq>
        </p>
      </div>
    </>
  )
}

export default Simple404
