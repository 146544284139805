import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import SimpleLayout from '@src/components/SimpleLayout'
import messages from '@utils/messages'
import { sendErrorPageEvent } from '@src/utils/analytics'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(196),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1,
  },
  // Line-height fix for Firefox and Edge
  '@supports (-ms-ime-align:auto)': {
    title: {
      lineHeight: 0.3,
      margin: theme.spacing(50, 0, 0),
    },
  },
  message: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    marginTop: theme.spacing(6),
  },
  statusCode: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    margin: `${theme.spacing(4)}px 0`,
  },
}))

const GenericError: React.FC<{ statusCode: number }> = ({ statusCode }) => {
  const classes = useStyles()

  useEffect(() => {
    sendErrorPageEvent(
      statusCode,
      'Oops Something has gone wrong and we are working to fix it.'
    )
  }, [])

  return (
    <SimpleLayout className={classes.root}>
      <Container maxWidth="md">
        <h1 className={classes.title}>
          <FormattedMessage id="ERROR_GENERIC_TITLE" defaultMessage="Oops!" />
        </h1>
        <div className={classes.message}>
          <FormattedMessage {...messages.ERROR_GENERIC_MESSAGE} />
        </div>
        <div className={classes.statusCode}>
          <FormattedMessage
            {...messages.ERROR_GENERIC_MESSAGE_STATUS}
            values={{ statusCode }}
          />
        </div>
      </Container>
    </SimpleLayout>
  )
}

export default GenericError
